// brands.js
export const brands = [
  {
    brand: "megsApp",
    brand_logo: "https://storage.googleapis.com/megs_assets/brand_logos/megsapp.png",
    brand_logo_portal: "https://storage.googleapis.com/megs_assets/brand_logos/megsapp_portal_updated.png",
    brand_domain: "megsapp.com",
  },
  {
    brand: "Tupp Connect",
    brand_logo: "https://storage.googleapis.com/megs_assets/brand_logos/tupp_connect.png",
    brand_logo_portal: "https://storage.googleapis.com/megs_assets/brand_logos/tupp_connect_portal.png",
    brand_domain: "tuppconnect",
  },
  {
    brand: "FIO Connect",
    brand_logo: "https://storage.googleapis.com/megs_assets/brand_logos/fio_connect.png",
    brand_logo_portal: "https://storage.googleapis.com/megs_assets/brand_logos/fio_connect_portal.png",
    brand_domain: "fioconnect.co.za",
  },
  {
    brand: "Honey Connect",
    brand_logo: "https://storage.googleapis.com/megs_assets/brand_logos/honey_connect.png",
    brand_logo_portal: "https://storage.googleapis.com/megs_assets/brand_logos/honey_connect_portal.png",
    brand_domain: "honeyconnect.co.za",
  },
];