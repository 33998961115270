import {
    Button,
    InputAdornment,
    MenuItem,
    Stack,
    TextField,
  } from "@mui/material";
  import PropTypes from "prop-types";
  // components
  import Iconify from "../../../../components/iconify";
  import CustomDateRangePicker from "src/components/CustomDateRangeRicker/CustomDateRangePicker";

  const INPUT_WIDTH = 160;

  RuleManagementToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filterName: PropTypes.string, // For search text
    onFilterName: PropTypes.func, // To handel the search text change
    filterService: PropTypes.string, // Types of  rules : all, individual , bundle , basketsss
    onFilterService: PropTypes.func,  // To Handel different types of rule tabs
    optionsService: PropTypes.arrayOf(PropTypes.string),
    onResetFilter: PropTypes.func,
    // filterStartDate: PropTypes.instanceOf(Date),
    // filterEndDate: PropTypes.instanceOf(Date),
    // onFilterStartDate: PropTypes.func,
    // onFilterEndDate: PropTypes.func,
  };

  export default function RuleManagementToolbar({
    isFiltered,
    filterName,
    onFilterName,
    filterService,
    onResetFilter,
    optionsService,
    onFilterService,
    // filterStartDate,
    // onFilterStartDate,
    // filterEndDate,
    // onFilterEndDate,
  }) {
    return (
      <Stack
        spacing={2}
        alignItems="center"
        direction={{
          xs: "column",
          md: "row",
        }}
        sx={{ px: 2.5, py: 3 }}
      >



        <TextField
          fullWidth
          value={filterName}
          onChange={onFilterName}
          placeholder="Search Rule"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
              </InputAdornment>
            ),
          }}
        />

        {isFiltered && (
          <Button
            color="error"
            sx={{ flexShrink: 0 }}
            onClick={onResetFilter}
            startIcon={<Iconify icon="eva:trash-2-outline" />}
          >
            Clear
          </Button>
        )}
      </Stack>
    );
  }
  