import { Button, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getDistributorsByLevels } from "src/api/distribution-api";
import Iconify from "src/components/iconify";
import { useSettingsContext } from "src/components/settings";
import AddLeveledSubDistributor from "src/sections/@dashboard/sub-distributor-levels/AddLeveledSubDistributor";
import LeveledSubDistributorList from "src/sections/@dashboard/sub-distributor-levels/LeveledSubDistributorList";
import SubDistributorList from "src/sections/@dashboard/sub-distributor-levels/SubDistributorList";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";

const SubDistributorsLevelsPage = () => {
  const { themeStretch } = useSettingsContext();
  const [showAddAppUser, setShowAddAppUser] = useState(false);
  const [parent, setParent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { distributor_i_customer, id, level } = useParams();

  useEffect(() => {
    async function fetchDistributorsByLevels() {
      setIsLoading(true);
      try {
        const response = await getDistributorsByLevels(distributor_i_customer, level, id);
        setParent(response.data.parent);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching distributors:", error);
      }
      setIsLoading(false);
    }

    fetchDistributorsByLevels();
  }, [distributor_i_customer, level, id]);

  return (
    <>
      <Helmet>
        <title>Sub-Distributors</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              heading="Sub-Distributors"
              links={[{ name: "Sub-Distributors", href: PATH_DASHBOARD.subDistributors.list }, { name: level ? `Level ${level}` : `List` }]}
              action={
                level && level !== "4" ? (
                  <Button
                    variant="contained"
                    component={Link}
                    onClick={() => {
                      setShowAddAppUser(!showAddAppUser);
                    }}
                    startIcon={<Iconify icon={"eva:plus-fill"} />}
                  >
                    Add Sub-Distibutor
                  </Button>
                ) : (
                  ""
                )
              }
            />
          </Grid>
        </Grid>

        {showAddAppUser && level && <AddLeveledSubDistributor parent={parent}/>}

        {!level && <SubDistributorList selectedRole={selectedRole} />}
        {level && <LeveledSubDistributorList selectedRole={selectedRole} />}
      </Container>
    </>
  );
};

export default SubDistributorsLevelsPage;
