import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRedirectSVIXURL } from "src/api/megs-api-api";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { useSettingsContext } from "src/components/settings";
import { useSnackbar } from "src/components/snackbar";

const ManageWebHooksPage = () => {
  const { themeStretch } = useSettingsContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [svixURL, setSvixURL] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function redirectToSVIXPortal() {
      setIsLoading(true);
      try {
        const response = await getRedirectSVIXURL(i_customer);
        if (response.status_code === 1001) {
          if (response.data) {
            setSvixURL(response.data);
            // window.open(response.data, "_blank");
          }
          // navigate(PATH_DASHBOARD.general.app);
        }
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
      setIsLoading(false);
    }

    redirectToSVIXPortal();
  }, []);

  useEffect(() => {
    console.log("Inside Useffect", svixURL);
  }, [svixURL]);

  return (
    <>
      <Helmet>
        <title>Web Hooks</title>
      </Helmet>

      {isLoading ? (
        <LoadingScreen />
      ) : (
        <iframe
          src={svixURL}
          style={{ width: "100%", height: "100%", border: "none" }}
          allowtransparency="true"
          allow="clipboard-write"
          loading="lazy"
        />
      )}

      {/* {svixURL && (
        <iframe
          src={svixURL}
          style={{ width: "100%", height: "100%", border: "none" }}
          allowtransparency="true"
          allow="clipboard-write"
          // loading="lazy"
        />
      )} */}
    </>
  );
};

export default ManageWebHooksPage;
