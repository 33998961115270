import PropTypes from "prop-types";
// @mui
import { Stack } from "@mui/material";
// components
import Logo from "../../components/logo";
//
import { StyledContent, StyledRoot, StyledSection, StyledSectionBg } from "./styles";

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  return (
    <StyledRoot
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Logo
        sx={{
          zIndex: 9,
          position: "absolute",
          mt: { xs: 2, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection
        sx={{
          flex: { xs: 1, md: 0.7 },
          marginBottom: { xs: 2, md: 0 },
          backgroundImage: `url('/login-logos/2.webp')`,
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      >
        <StyledSectionBg />
      </StyledSection>

      <StyledContent
        sx={{
          flex: { xs: 1, md: 0.3 },
          mx: { xs: 2, md: 5 },
          width: { xs: "auto", md: "75%" },
        }}
      >
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
