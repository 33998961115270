import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDistributorList } from "src/api/distributor-api";
import { getDistributorNoticeDetails } from "src/api/portal-notices-api";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import DistributorNoticeDetails from "src/sections/@dashboard/distributor-notices/details";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../components/settings";
import { PATH_DASHBOARD } from "../../routes/paths";

export default function DistributorNoticesDetailsPage() {
  const { themeStretch } = useSettingsContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;

  const { id } = useParams();
  const [notice, setNotice] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roleTypes, setRoleTypes] = useState([
    { label: "All", value: "all" },
    { label: "Admin", value: "admin" },
    { label: "Distributor", value: "distributor" },
    { label: "Sub Distributor", value: "sub-distributor" },
    { label: "Support Agent", value: "support_agent" },
  ]);
  const [distributors, setDistributors] = useState([]);

  useEffect(() => {
    const fetchNoticeDetails = async () => {
      try {
        setLoading(true);
        const result = await getDistributorNoticeDetails(id);
        if (result) {
          // console.log("fetchNoticeDetails: ", result);
          if (result.status_code === 1001) {
            setNotice(result.data);
          }
        }
      } catch (error) {
        // console.log("error: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNoticeDetails();
  }, [id]);

  useEffect(() => {
    async function fetchDistributors() {
      try {
        const response = await getDistributorList();
        console.log("Distributor Response", response);
        if (selectedRole.role !== "admin") {
          setDistributors(response.data.filter((distributor) => distributor.i_customer === i_customer));
        } else {
          response.data.unshift({ i_customer: 0, company_name: "All" });
          setDistributors(response.data);
        }
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
    }

    fetchDistributors();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title> Notice Details Portal</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Notice Details"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Portal Notices",
              href: PATH_DASHBOARD.admin.distributorNotices,
            },
            { name: `${id}` },
          ]}
        />
        {loading ? <LoadingScreen /> : <DistributorNoticeDetails notice={notice} setNotice={setNotice} roleTypes={roleTypes} distributors={distributors} />}
      </Container>
    </>
  );
}
