// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: "general",
  //   items: [
  //     { title: "app", path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "management",
    items: [
      // ADMIN
      {
        title: "Admin",
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.user,
        children: [
          { title: "Admin Users", path: PATH_DASHBOARD.admin.adminUsers, roles: ["admin"] },
          { title: "App Users", path: PATH_DASHBOARD.admin.appUsers, roles: ["admin", "distributor"] },
          { title: "Portal Notices", path: PATH_DASHBOARD.admin.distributorNotices, roles: ["admin", "distributor"] },
        ],
        roles: ["admin", "distributor"],
      },
      // DISTRIBUTORS
      {
        title: "Reports",
        path: PATH_DASHBOARD.distributors.root,
        icon: ICONS.user,
        children: [
          {
            title: "Transaction History",
            path: PATH_DASHBOARD.distributors.transactions,
            roles: ["distributor", "support_agent"],
          },
          // { title: "Dashboard", path: PATH_DASHBOARD.distributors.dashboard },
          // { title: "Graphs", path: PATH_DASHBOARD.distributors.graphs, roles: ["admin"] },
          { title: "SIM Cards", path: PATH_DASHBOARD.distributors.simCards, roles: ["admin", "distributor", "support_agent"] },
          { title: " Customer List", path: PATH_DASHBOARD.distributors.statusList, roles: ["admin", "distributor", "support_agent"] },
          { title: "Sales Report", path: PATH_DASHBOARD.distributors.salesReport, roles: ["honey"] },
          { title: "Distributor Report", path: PATH_DASHBOARD.distributors.distributorReport, roles: ["admin", "distributor"] },
          { title: "Vouchers", path: PATH_DASHBOARD.distributors.voucherList, roles: ["admin", "support_agent"] },
        ],
        roles: ["admin", "distributor", "honey", "support_agent"],
      },
      // Customer Care
      {
        title: "Customer Care",
        path: PATH_DASHBOARD.customerCare.root,
        icon: ICONS.cart,
        children: [
          { title: "port-in list", path: PATH_DASHBOARD.customerCare.portIn, roles: ["admin", "support_agent"] },
          { title: "Verify self RICA", path: PATH_DASHBOARD.customerCare.selfRicaList, roles: ["admin"] },
          { title: "SIM Swap", path: PATH_DASHBOARD.customerCare.simSwap, roles: ["admin"] },
          { title: "Customer Top-up", path: PATH_DASHBOARD.customerCare.topup, roles: ["admin"] },
          {
            title: "Support Ticket Escalation",
            path: PATH_DASHBOARD.customerCare.escalation,
            roles: ["admin", "distributor", "sub-distributor", "support_agent"],
          },
          { title: "Unsubscribe from Marketing", path: PATH_DASHBOARD.customerCare.unsubscribe, roles: ["admin"] },
        ],
        roles: ["admin", "sub-distributor", "distributor", "support_agent"],
      },
      // RICA & SELF RICA
      {
        title: "RICA / PORT",
        path: PATH_DASHBOARD.rica.root,
        icon: ICONS.cart,
        children: [
          // { title: "Verify self RICA", path: PATH_DASHBOARD.rica.selfRica, roles: ["admin"] },
          // { title: "Status list", path: PATH_DASHBOARD.rica.list, roles: ["admin"] },
          {
            title: "Rica Customer",
            path: `${PATH_DASHBOARD.rica.selfRicaForm}/agent_rica`,
            roles: ["admin", "distributor", "sub-distributor", "honey", "support_agent", "agent"],
          },
          {
            title: "Pre Rica",
            path: `${PATH_DASHBOARD.rica.selfRicaForm}/pre_rica`,
            roles: ["admin"],
          },
          { title: "Port Customer", path: PATH_DASHBOARD.rica.port, roles: ["admin", "distributor", "sub-distributor", "honey", "support_agent", "agent"] },
        ],
        roles: ["admin", "distributor", "sub-distributor", "honey", "support_agent", "agent"],
      },

      // CX
      {
        title: "CX",
        path: PATH_DASHBOARD.cx.root,
        icon: ICONS.user,
        children: [
          { title: "Message Templates", path: PATH_DASHBOARD.cx.messaging.list },
          { title: "Notices", path: PATH_DASHBOARD.cx.notices.list },
        ],
        roles: ["admin"],
      },
      // Reports

      // {
      //   title: "Reports",
      //   path: PATH_DASHBOARD.reports.root,
      //   icon: ICONS.invoice,
      //   children: [{ title: "Reports", path: PATH_DASHBOARD.reports.list }],
      //   roles: ["admin"],
      // },

      // IOT
      {
        title: "IOT",
        path: PATH_DASHBOARD.iot.root,
        icon: ICONS.ecommerce,
        children: [
          { title: "Accounts", path: PATH_DASHBOARD.iot.list },
          // { title: "Usage", path: PATH_DASHBOARD.iot.usage },
        ],
        roles: ["admin", "iot"],
      },
      // SIM CARD OPERATIONS
      {
        title: "SIM Cards",
        path: PATH_DASHBOARD.simCards.root,
        icon: ICONS.ecommerce,
        children: [
          { title: "SIM Assignment", path: PATH_DASHBOARD.simCards.assignment, roles: ["admin"] },
          { title: "SIM Scan Out", path: PATH_DASHBOARD.simCards.scanOut, roles: ["admin", "distributor"] },
          { title: "SIM Orders", path: PATH_DASHBOARD.simCards.orders, roles: ["admin", "distributor"] },
          { title: "SIM Status", path: PATH_DASHBOARD.simCards.status, roles: ["admin"] },
        ],
        roles: ["admin", "distributor"],
      },
      // Resellers
      {
        title: "Resellers",
        path: PATH_DASHBOARD.resellers.root,
        icon: ICONS.ecommerce,
        children: [
          { title: "Transactions", path: PATH_DASHBOARD.resellers.transactions, roles: ["distributor", "reseller"] },
          { title: "Sell Product", path: PATH_DASHBOARD.resellers.sellProduct, roles: ["distributor", "reseller"] },
        ],
        roles: ["distributor", "reseller"],
      },
      // Vouchers
      // {
      //   title: "Vouchers",
      //   path: PATH_DASHBOARD.vouchers.root,
      //   icon: ICONS.ecommerce,
      //   children: [{ title: "Vouchers", path: PATH_DASHBOARD.vouchers.list }],
      //   roles: ["admin", "support_agent"],
      // },
      // Wallets
      {
        title: "Wallets",
        path: PATH_DASHBOARD.wallets.root,
        icon: ICONS.ecommerce,
        children: [{ title: "Bulk Payments", path: PATH_DASHBOARD.wallets.root }],
        roles: ["distributor"],
      },
      {
        title: "Subscriptions",
        path: PATH_DASHBOARD.subscriptions.root,
        icon: ICONS.ecommerce,
        children: [
          { title: "Manage Subscriptions", path: PATH_DASHBOARD.subscriptions.manageSubscriptions, roles: ["distributor"] },
          { title: "Batch Requests", path: PATH_DASHBOARD.subscriptions.subscriptionRequests, roles: ["distributor"] },
          { title: "Active Subscriptions", path: PATH_DASHBOARD.subscriptions.activeSubscriptions, roles: ["admin", "distributor"] },
        ],
        roles: ["admin", "distributor"],
      },
      {
        title: "Products",
        path: PATH_DASHBOARD.products.root,
        icon: ICONS.ecommerce,
        children: [
          { title: "Product List", path: PATH_DASHBOARD.products.productList, roles: ["admin"] },
          { title: "Product Group", path: PATH_DASHBOARD.products.productGroupList, roles: ["admin"] },
        ],
        roles: ["admin"],
      },
      {
        title: "Webhooks",
        path: PATH_DASHBOARD.webhooks.root,
        icon: ICONS.ecommerce,
        children: [{ title: "Manage Webhooks", path: PATH_DASHBOARD.webhooks.manage, roles: ["distributor"] }],
        roles: ["distributor"],
      },
      {
        title: "Training Videos",
        path: PATH_DASHBOARD.trainingVideos.root,
        icon: ICONS.ecommerce,
        children: [
          { title: "Training Videos", path: PATH_DASHBOARD.trainingVideos.list, roles: ["admin"] },
          { title: "Add Training Videos", path: PATH_DASHBOARD.trainingVideos.addVideos, roles: ["admin"] },
          { title: "Agent Videos", path: PATH_DASHBOARD.trainingVideos.agentVideos, roles: ["admin", "distributor", "sub-distributor", "support_agent"] },
          { title: "Consumer Videos", path: PATH_DASHBOARD.trainingVideos.consumerVideos, roles: ["admin", "distributor", "sub-distributor", "support_agent"] },
        ],
        roles: ["admin", "distributor", "sub-distributor", "support_agent"],
      },
      {
        title: "Sub-Distributors",
        path: PATH_DASHBOARD.subDistributors.list,
        icon: ICONS.ecommerce,
        roles: ["admin"],
      },
      {
        title: "Rule-Management",
        path: PATH_DASHBOARD.ruleManagement.root,
        icon: ICONS.ecommerce,
        roles: ["admin"],
      },
    ],
  },
];

export default navConfig;
