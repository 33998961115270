import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// @mui
import { Button, Card, Container, Divider,  Tab, Table, TableBody, TableContainer, Tabs } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "../../components/snackbar";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import { useSelector } from "react-redux";
import ConfirmDialog from "../../components/confirm-dialog";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import Scrollbar from "../../components/scrollbar";
import { useSettingsContext } from "../../components/settings";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,

  emptyRows,
  useTable,
} from "../../components/table";


// sections


import LoadingScreen from "../../components/loading-screen";
import { RuleManagementToolbar } from "src/sections/@dashboard/rule-management/list";
import Iconify from "src/components/iconify";

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = ["all", "individual", "bundle", "basket"];




// ----------------------------------------------------------------------

export default function RulesManagementPage() {
  const theme = useTheme();

  // const user = useSelector((state) => state.auth.user);
  // const selectedRole = useSelector((state) => state.auth.selectedRole);

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    setPage,
    selected,
    setSelected,

    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState("");

  const [openConfirm, setOpenConfirm] = useState(false);

  const [filterStatus, setFilterStatus] = useState("all");

  // default start_date range is 30 days ago

  const [tableHead,setTableHead] = useState([])

  const [filterService, setFilterService] = useState("all");

  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [isLoading, setIsLoading] = useState(false); // for topup button

  // const i_customer = 4161;
  const i_customer = useSelector((state) => state.auth.selectedRole.i_customer);








  // const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const denseHeight = dense ? 56 : 76;

  // const isFiltered = filterStatus !== "all" || filterName !== "" || filterService !== "all" || (!!filterStartDate && !!filterEndDate);
  const isFiltered = false;


  const TABS = [
    { value: "all", label: "All", color: "primary" },
    { value: "individual ", label: "Individual ", color: "primary" },
    { value: "bundle", label: "Bundle", color: "primary" },
    { value: "basket", label: "Basket", color: "primary" },
  ];




  const handleFilterStatus = (event, newValue) => {
    setPage(0);
    if(newValue == "basket"){
      setTableHead([
        { id: "basket_name", label: "Basket Name", align: "left" },
        { id: "price_total", label: "Price Total", align: "left" },
        { id: "rewards", label: "Rewards", align: "left" },
      ])
    }else if(newValue == "all"){
      setTableHead([])
    }
    else{
      setTableHead([
        { id: "item_name", label: "Name of Item", align: "left" },
        { id: "barcode_number", label: "Barcode Number", align: "left" },
        { id: "item_quantity", label: "How many Items", align: "left" },
        { id: "rewards", label: "Rewards", align: "left" },
      ])
    }
    setFilterStatus(newValue);
  };

  const handleFilterName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event) => {
    setPage(0);
    setFilterService(event.target.value);
  };





  const handleCreateNewRule = (e) => {
    e.preventDefault();
    navigate(PATH_DASHBOARD.ruleManagement.addRule);
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterService("all");
  };

  const totalRows = 1

  return (
    <>
      <>
        <Helmet>
          <title> Rule Management | megsApp </title>
        </Helmet>

        <Container maxWidth={themeStretch ? false : "xl"}>
          <CustomBreadcrumbs
            heading="Rule Management"
            links={[
              {
                name: "Dashboard",
                href: PATH_DASHBOARD.root,
              },
              {
                name: "Rule Management",
              },
            ]}
            action={
              <Button
                variant="contained"
                component={Link}
                onClick={handleCreateNewRule}
                startIcon={<Iconify icon={"eva:plus-fill"} />}
              >
                Add New Rule
              </Button>
            }
          />

          <Card>
            <Tabs
              value={filterStatus}
              onChange={handleFilterStatus}
              sx={{
                px: 2,
                bgcolor: "background.neutral",
              }}
            >
              {TABS.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>

            <Divider />

            <RuleManagementToolbar
              isFiltered={isFiltered}
              filterName={filterName}
              onFilterName={handleFilterName}
              filterService={filterService}
              onFilterService={handleFilterService}
              optionsService={SERVICE_OPTIONS}
              onResetFilter={handleResetFilter}
            />
            {loading ? (
              <>
                <LoadingScreen />
              </>
            ) : (
              <TableContainer sx={{ position: "relative", overflow: "unset" }}>
                <Scrollbar>
                  <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={tableHead}
                      rowCount={tableData.length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />

                    <TableBody>
                      <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />
                      <TableNoData isNotFound={true} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
            )}

            <TablePaginationCustom
              page={page}
              rowsPerPage={5}
              onPageChange={onChangePage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
              count={totalRows}
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>

      </>
    </>
  );
}


