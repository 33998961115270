import { LoadingButton } from "@mui/lab";
import { Card, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useSnackbar } from "src/components/snackbar";
import { useSettingsContext } from "../../components/settings";

import { useState } from "react";
import { listAllMsisdns } from "src/api/megs-api-api";
import UnsubscribeMsisdnList from "src/sections/@dashboard/unsubscribe-marketing/UnsubscribeMsisdnList";

export default function UnsubscribeMarketing() {
  const { themeStretch } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [msisdn, setMsisdn] = useState();
  const [msisdnList, setMsisdnList] = useState([]);
  const [unsubscribedStats, setUnsubscribedStats] = useState(false);

  const handleSubmit = async () => {
    if (msisdn) {
      try {
        setLoading(true);
        const response = await listAllMsisdns(msisdn);
        if (response.status_code === 1001) {
          setMsisdnList(response.data);
          setUnsubscribedStats(true);
        } else {
          enqueueSnackbar(response.data, {
            variant: "error",
          });
          console.error(response);
        }
      } catch (ex) {
        console.error(ex);
        console.error(ex.message);
      }
    } else {
      enqueueSnackbar("Please fill out the necessary details", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleUnsubscribedStats = () => {
    setUnsubscribedStats(false);
    setMsisdn("");
  };

  const handleMsisdnChange = (e) => {
    setMsisdn(e.target.value);
    unsubscribedStats && setUnsubscribedStats(false);
  };

  return (
    <>
      <Helmet>
        <title>Unsubscribe From Marketing</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Typography variant="h4">Unsubscribe From Marketing</Typography>

        <Card sx={{ px: 3, py: 5, my: 5 }}>
          <Grid
            container
            spacing={2}
            sx={{
              my: 2,
            }}
          >
            <Grid item xs={12} md={8}>
              <TextField variant="outlined" fullWidth label="MSISDN" name="msisdn" value={msisdn} onChange={(e) => handleMsisdnChange(e)} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={3} direction="row" alignItems="flex-end" sx={{ mt: 2, justifyContent: "flex-end" }}>
                <LoadingButton onClick={handleSubmit} loading={loading} variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
        {unsubscribedStats && <UnsubscribeMsisdnList msisdnList={msisdnList} handleUnsubscribedStats={handleUnsubscribedStats} />}
      </Container>
    </>
  );
}
