import { Box, Card, CircularProgress, Table, TableBody, TableContainer, Grid, Autocomplete, TextField, Divider, Tabs, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";

import { listAdminAppUsers, listAppUsers } from "src/api/megs-api-api";

import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import SubDistributorTableRow from "./SubDistributorTableRow";
import { getDistributors } from "src/api/distribution-api";
import { useSelector } from "react-redux";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
export default function SubDistributorList() {
  const [distributors, setDistributors] = useState([]);
  const [distributorsData, setDistributorsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const navigate = useNavigate();

  const TABLE_HEAD = [
    { id: "company_name", label: "Name", align: "left" },
    { id: "contact_first_name", label: "Contact First Name", align: "left" },
    { id: "contact_last_name", label: "Contact Last Name", align: "left" },
    { id: "email", label: "Email", align: "left" },
  ];

  const TABS = [{ value: "all", label: "All", color: "primary", count: distributorsData.length }];

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  const [search, setSearch] = useState("");

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    async function fetchDistributors() {
      setIsLoading(true);
      try {
        const response = await getDistributors();
        if (selectedRole.role !== "admin") {
          setDistributors(response.data.list.filter((distributor) => distributor.i_customer === i_customer));
          setDistributorsData(response.data.list.filter((distributor) => distributor.i_customer === i_customer));
        } else {
          // add All Distributors option
          setDistributors(response.data.list);
          setDistributorsData(response.data.list);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching distributors:", error);
      }
      setIsLoading(false);
    }

    fetchDistributors();
  }, []);

  const handleDistributorLevels = (e, id, i_customer, level) => {
    navigate(PATH_DASHBOARD.subDistributors.levels(i_customer, id, 1));
  };

  useEffect(() => {
    const filteredDistributorData = distributorsData?.filter((data) => {
      const isSearchMatch =
        data?.company_name?.toLowerCase().includes(search.toLowerCase()) ||
        data?.contact_first_name?.toLowerCase().includes(search.toLowerCase()) ||
        data?.contact_last_name?.toString().includes(search.toString()) ||
        data?.email?.toString().includes(search.toString());

      return isSearchMatch;
    });

    setDistributors([...filteredDistributorData]);
  }, [search]);

  if (isLoading) return <LoadingScreen />;

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        />{" "}
        {selectedRole.role === "admin" && (
          <Grid container spacing={2}>
            {/* <Grid item sx={{ m: 2 }} xs={6}>
              <Autocomplete
                value={selectedDistributor}
                onChange={handleDistributorChange}
                options={distributors}
                getOptionLabel={(option) => option.company_name || ""}
                renderInput={(params) => <TextField {...params} label="Select Distributor" placeholder="Select Distributor" fullWidth />}
              />
            </Grid> */}
            <Grid item sx={{ m: 2 }} xs={12}>
              <TextField
                fullWidth
                value={search}
                placeholder="Search..."
                onChange={onSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={distributors.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {distributors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((distributor) => (
                  <SubDistributorTableRow
                    key={distributor.id}
                    row={distributor}
                    onViewRow={(e) => handleDistributorLevels(e, distributor?.id, distributor?.i_customer, 1)}
                  />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, distributors.length)} />
                <TableNoData isNotFound={distributors.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={distributorsData.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
