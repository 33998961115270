import { LoadingButton } from "@mui/lab";
import { Button, Card, Container, Grid, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDistributors } from "src/api/distribution-api";
import { checkUserExists } from "src/api/megs-api-api";
import Iconify from "src/components/iconify";
import { useSettingsContext } from "src/components/settings";
import { useSnackbar } from "src/components/snackbar";
import AddAppUserRole from "src/sections/@dashboard/appusers/AddAppUserRole";
import AppUserList from "src/sections/@dashboard/appusers/AppUsersList";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";

const AppUsersPage = () => {
  const { themeStretch } = useSettingsContext();
  const [showAddAppUser, setShowAddAppUser] = useState(false);
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const [userName, setUserName] = useState("");
  const [userExist, setUserExist] = useState(false);
  const [userData, setUserData] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState();

  const validateUser = async () => {
    if (userName !== "") {
      setIsLoading(true);
      const result = await checkUserExists(userName);
      if (result.status_code === 1001 && result.data !== null) {
        setUserExist(true);
        setUserData(result.data);
        enqueueSnackbar("User already exists. Please select role to be added.", { variant: "success" });
        setTimeout(() => {
          closeSnackbar();
        }, 4000);
        setIsLoading(false);
      } else {
        setUserData(null);
        setUserExist(true);
        enqueueSnackbar("User doesn't exist. Please fill out the neccessary details.", { variant: "error" });
        setTimeout(() => {
          closeSnackbar();
        }, 4000);
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("Enter a valid email address", { variant: "error" });
      setTimeout(() => {
        closeSnackbar();
      }, 2000);
    }
  };

  useEffect(() => {
    setUserName("");
  }, [showAddAppUser]);

  useEffect(() => {
    async function fetchDistributors() {
      try {
        const response = await getDistributors();
        if (selectedRole.role !== "admin") {
          setDistributors(response.data.list.filter((distributor) => distributor.i_customer === i_customer));
        } else {
          // add All Distributors option
          response.data.list.unshift({ i_customer: "All", company_name: "All" });
          setDistributors(response.data.list);
        }
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
    }

    fetchDistributors();
  }, []);

  const onDistributorChange = (distributor_details) => {
    console.log("distributor_details", distributor_details);
    setSelectedDistributor(distributor_details.i_customer);
  };

  return (
    <>
      <Helmet>
        <title>App Users</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              heading="App Users"
              links={[{ name: "Dashboard", href: PATH_DASHBOARD.root }, { name: "App Users" }]}
              action={
                <Button
                  variant="contained"
                  component={Link}
                  onClick={() => {
                    setShowAddAppUser(!showAddAppUser);
                  }}
                  startIcon={<Iconify icon={"eva:plus-fill"} />}
                  disabled={selectedDistributor === "All"}
                >
                  Add App User
                </Button>
              }
            />
          </Grid>
          {showAddAppUser && (
            <Grid item xs={12}>
              <Card sx={{ p: 3, mb: 3 }}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="email"
                    label="Email Address"
                    onBlur={(e) => {
                      setUserName(e.target.value);
                    }}
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack spacing={3} direction={"row"} alignItems="flex-end" justifyContent="flex-end" sx={{ mt: 2 }}>
                    <LoadingButton
                      variant="contained"
                      loading={isLoading}
                      onClick={() => {
                        validateUser();
                      }}
                    >
                      Check
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>

        {userExist && <AddAppUserRole userData={userData} email={userName} distributor_i_customer={selectedDistributor} />}

        <AppUserList distributors={distributors} selectedRole={selectedRole} onChangeDistributor={onDistributorChange} />
      </Container>
    </>
  );
};

export default AppUsersPage;
