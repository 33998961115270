import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { getDistributors } from "src/api/distribution-api";
import { useSettingsContext } from "src/components/settings";
import { useSnackbar } from "src/components/snackbar";
import DistributorReportList from "src/sections/@dashboard/reports/distributor-reports/DistributorReportList";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";

const DistributorReportPage = () => {
  const { themeStretch } = useSettingsContext();
  const [showAddAppUser, setShowAddAppUser] = useState(false);
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const [userName, setUserName] = useState("");
  const [userExist, setUserExist] = useState(false);
  const [userData, setUserData] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState();

  useEffect(() => {
    async function fetchDistributors() {
      try {
        const response = await getDistributors();
        if (selectedRole.role !== "admin") {
          setDistributors(response.data.list.filter((distributor) => distributor.i_customer === i_customer));
        } else {
          // add All Distributors option
          response.data.list.unshift({ i_customer: "All", company_name: "All" });
          setDistributors(response.data.list);
        }
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
    }

    fetchDistributors();
  }, []);

  const onDistributorChange = (distributor_details) => {
    console.log("distributor_details", distributor_details);
    setSelectedDistributor(distributor_details.i_customer);
  };

  return (
    <>
      <Helmet>
        <title>App Users</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              heading="Distributor Reports"
              links={[{ name: "Reports", href: PATH_DASHBOARD.root }, { name: "Distributor Reports" }]}
            />
          </Grid>
        </Grid>

        <DistributorReportList distributors={distributors} selectedRole={selectedRole} onChangeDistributor={onDistributorChange} />
      </Container>
    </>
  );
};

export default DistributorReportPage;
