import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import ReactPlayer from "react-player";
import Image from "src/components/image";

// ----------------------------------------------------------------------

const StyledOverlay = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 8,
  width: "100%",
  height: "100%",
  position: "absolute",
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

// ----------------------------------------------------------------------

VideoCard.propTypes = {
  videoDetails: PropTypes.object.isRequired,
};

export default function VideoCard({ videoDetails }) {
  const { name, description, link, thumbnail } = videoDetails;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card sx={{ textAlign: "center", cursor: "pointer", height: "100%" }} onClick={handleClickOpen}>
        <Box sx={{ position: "relative" }}>
          <Image disabledEffect alt="thumbnail" src={thumbnail} ratio="16/9" />
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />

        <Typography variant="subtitle1" sx={{ m: 2.5 }}>
          {name}
        </Typography>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Typography variant="subtitle1" sx={{ m: 2.5 }}>
          {description}
        </Typography>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogActions sx={{ padding: "8px 16px !important" }}>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ height: "410px" }}>
          <ReactPlayer style={{ height: "100% !important;" }} url={link} width="100%" controls />
        </DialogContent>
      </Dialog>
    </>
  );
}
