import { Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import AddTrainingVideos from "src/sections/@dashboard/agent-videos/AddTrainingVideos";
import EditTrainingVideos from "src/sections/@dashboard/agent-videos/EditTrainingVideos";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../components/settings";
import { PATH_DASHBOARD } from "../../routes/paths";

export default function UploadTrainingVideosPage() {
  const { themeStretch } = useSettingsContext();
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>Upload Training Video Portal</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Training Videos"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Video",
            },
          ]}
        />
        {id ? <EditTrainingVideos /> : <AddTrainingVideos />}
      </Container>
    </>
  );
}
